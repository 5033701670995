


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import DisplayFiles from "@/views/DisplayDocument.vue";



@Component({ components: { HollowDotsSpinner,DisplayFiles } })
export default class NotificationComponent extends Vue {
  public notifications = [];
  public hollowLoader = false;
  public borrowerNotificationCount = 0;

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public async getNotification() {
    this.hollowLoader = JSON.parse(JSON.stringify(true));
    try {
      let response = await Axios.get(BASE_API_URL + "message/getNotification", {
        params: {
          userId: this.$userId
        }
      });
          
      this.notifications = response.data.data;
      this.borrowerNotificationCount = response.data.data.length;
      this.hollowLoader = JSON.parse(JSON.stringify(false));
    } catch (error) {
      this.hollowLoader = JSON.parse(JSON.stringify(false));
      console.log(error);
    }
  }


  public async changeNotificationisReadStatus(notificationId) {
    try {
      this.notifications = this.notifications.filter(
        e => e._id != notificationId
      );
      this.notifications = JSON.parse(JSON.stringify(this.notifications));
      let unReadedCommunications = [notificationId];
      let response = await Axios.post(
        BASE_API_URL + "communication/changeNotificationisReadStatus",
        {
          unReadedCommunications: unReadedCommunications
        });
      this.borrowerNotificationCount--;
    } catch (error) {
      console.log(error.data);
    }
  }

  //For viewing the document for email attachement
  public async viewPreview(data){
this.$refs.dfl['setInitialValuesForShowingSamplesWithHeading'](null,data)
  }

  mounted() {
    this.getNotification();
  }
}
